body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Source Sans Pro, sans-serif;
}

h1 {
  font-size: 28pt;
  color: #569cb7;
  font-weight: normal;
  margin: 0;
}

h2 {
  font-size: 23pt;
  color: #569cb7;
  font-weight: normal;
  margin: 0;
}

h3 {
  font-size: 18pt;
  color: #569cb7;
  font-weight: normal;
  margin: 0;
}

.editor {
  font-family: Source Sans Pro, sans-serif;
  height: 230px;
}
.editor-toolbar {
  font-family: Source Sans Pro, sans-serif;
}

.navbar-content {
  display: flex;
  background: transparent;
  flex-direction: row;
  align-items: center;
  height: 107.5px;
}

.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.page-header {
  display: flex;
  align-items: center;
  border-bottom: solid 1.5px rgb(225, 225, 225);
  margin: 90px 0 45px 0;
}

.list-container {
  display: flex;
  justify-content: center;
  margin-bottom: 15vh;
}

.filter-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  min-width: 225px;
  margin-left: 15px;
  border: solid 1px rgb(225, 225, 225);
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  position: sticky;
  top: 50px;
}

.job-list {
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-width: 820px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
}

.clinic-list {
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-width: 820px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 4000px) and (min-width: 1300px) {
  .page-header,
  .navbar-content,
  .list-container {
    width: 86%;
    max-width: 1300px;
  }
  .filter-panel {
    width: 24%;
  }
  .job-list,
  .clinic-list {
    width: 76%;
  }
}

@media only screen and (max-width: 1299.99px) {
  .page-header,
  .navbar-content,
  .list-container {
    width: 95%;
    max-width: 1100px;
  }
  .filter-panel {
    width: 24%;
  }
  .job-list,
  .clinic-list {
    width: 76%;
  }
}
