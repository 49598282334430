/* TOOLTIP STYLES */

.tooltip {
    display: flex;
    flex-direction: column;
    padding: 15px 25px 15px 25px;
    opacity: 1 !important;
    border: solid 1.25px gainsboro !important;
    font-weight: normal;
  }
  
  .__react_component_tooltip.place-top::after {
    border-top: 6px solid gainsboro !important;
  }
  