/* UI HOVER or CLICK FEEDBACK */

.feedback {
  cursor: pointer;
}

.feedback:hover {
  transform: scale(1.05);
}

.feedback:active {
  transform: scale(1.1);
}
