.leaflet-popup-close-button {
  display: none;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-content {
  -webkit-border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  border-radius: 3px !important;
}
