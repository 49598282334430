.map-container {
   display: flex;
   flex-direction: row;
   height: 80vh;
   width: 75vw;
}

.map-filter-panel {
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 25%;
   height: 100%;
}

.map {
   z-index: 0;
   height: 100%;
   width: 75%;
}