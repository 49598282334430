/* BLUE */

.blue {
    border: solid 1px rgb(70, 134, 159);
    text-shadow: 2.5px 2.5px rgb(70, 134, 159);
    background: linear-gradient( to bottom, rgb(96, 166, 193), rgb(78, 142, 167) 50%);
}

.blue:focus {
    outline: 0;
}

.blue:hover {
    background: linear-gradient( to bottom, rgb(78, 142, 167), rgb(96, 166, 193) 50%);
}

.blue:active {
    background: linear-gradient( to bottom, rgb(68, 132, 167), rgb(76, 146, 173) 50%);
}

/* GRAY */

.gray {
    border: solid 1px rgb(125, 125, 125);
    opacity: 0.8;
    background: linear-gradient( to bottom, rgb(200, 200, 200), rgb(120, 120, 120) 50%);
}

.gray:focus {
    outline: 0;
}

/* GREEN */

.green {
    border: solid 1px rgb(74, 157, 135);
    text-shadow: 2.5px 2.5px rgb(74, 157, 135);
    background: linear-gradient( to bottom, rgb(88, 185, 159), rgb(74, 157, 135) 50%);
}

.green:focus {
    outline: 0;
}

.green:hover {
    background: linear-gradient( to bottom, rgb(74, 157, 135), rgb(88, 185, 159) 75%);
}

.green:active {
    background: linear-gradient( to bottom, rgb(54, 137, 115), rgb(68, 165, 139) 75%);
}

/* RED */

.red {
    border: solid 1px rgb(171, 57, 48);
    text-shadow: 2.5px 2.5px rgb(171, 57, 48);
    background: linear-gradient( to bottom, rgb(206, 103, 100), rgb(171, 57, 48) 50%);
}

.red:focus {
    outline: 0;
}

.red:hover {
    background: linear-gradient( to bottom, rgb(171, 57, 48), rgb(196, 93, 90) 75%);
}

.red:active {
    background: linear-gradient( to bottom, rgb(151, 37, 28), rgb(186, 83, 80) 75%);
}

/* YELLOW */

.yellow {
    border: solid 1px rgb(202, 136, 52);
    text-shadow: 2.5px 2.5px rgb(202, 136, 52);
    background: linear-gradient( to bottom, rgb(241, 185, 105), rgb(202, 136, 52) 50%);
}

.yellow:focus {
    outline: 0;
}

.yellow:hover {
    background: linear-gradient( to bottom, rgb(202, 136, 52), rgb(231, 175, 95) 75%);
}

.yellow:active {
    background: linear-gradient( to bottom, rgb(182, 116, 32), rgb(221, 165, 95) 75%);
}